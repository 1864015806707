#header {
    text-transform: uppercase;
    background-color: #067302;
}

#header a {
    text-decoration: none;
    color: #fff;
}

#header button {
    color: #fff !important;
}

#header .navbar-brand {
    margin-right: 50px;
}

#header nav {
    max-height: '100px';
}

#header .navbar {
    display: flex;
    justify-content: center;
}

#header .navbar a {
    margin: 0 20px;
}

#header .navbar button {
    text-transform: uppercase;
    text-decoration: none;
    color: black;
}